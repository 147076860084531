<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      size="xl"
      :visible="isPreviewTemplate"
      title="Preview template"
      centered
      ok-hide
      cancel-hide
      cancel-variant="outline-secondary"
      @hide="$emit('update:is-preview-template', false)"
      @change="(val) => $emit('update:is-preview-template', val)"
    >
      <div v-html="htmlPreview" />
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import 'vue-advanced-cropper/dist/style.css'

export default {
  components: {
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    isPreviewTemplate: {
      type: Boolean,
      required: true,
    },
    htmlPreview: {
      type: String,
      default: '',
      require: true,
    },
  },
}
</script>
<style lang="scss">
.rotated {
  transform: rotate(-90deg);
  /* Equal to rotateZ(45deg) */
}
</style>
