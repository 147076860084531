<template>
  <b-sidebar
    id="add-update-mailchimp-account-sidebar"
    :visible="isViewMailchimpTemplateSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-view-mailchimp-template-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          View mailchimp template
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <!--  Name -->
        <b-form-group
          label="Name"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="mailchimpTemplate.name"
            readonly
            autofocus
            trim
            placeholder="PwC Marketplace MY"
          />
        </b-form-group>

        <!-- Region -->
        <b-form-group
          label="Region"
          label-for="account-region"
        >
          <v-select
            v-model="mailchimpTemplate.regionId"
            disabled
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="regionOptions"
            :reduce="(val) => val.value"
            :clearable="false"
            input-id="account-region"
          />
        </b-form-group>
        <!-- Mail Action -->
        <b-form-group
          label="Mail action"
          label-for="mailAction"
        >
          <v-select
            v-model="mailchimpTemplate.mailAction"
            multiple
            disabled
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :reduce="(val) => val.value"
            :clearable="false"
            input-id="account-region"
          />
        </b-form-group>
        <!-- Account -->
        <b-form-group
          label="Account"
          label-for="account"
        >
          <b-form-input
            id="account"
            v-model="mailchimpTemplate.account"
            readonly
            trim
          />
        </b-form-group>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isViewMailchimpTemplateSidebarActive',
    event: 'update:is-view-mailchimp-template-sidebar-active',
  },
  props: {
    isViewMailchimpTemplateSidebarActive: {
      type: Boolean,
      required: true,
    },
    regionOptions: {
      type: Array,
      required: true,
    },
    mailchimpTemplate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const toast = useToast()
    const blankMailchimpAccount = {
      name: '',
      regionId: '',
      mailAction: [],
      account:'',
    }
    const mailchimpAccount = ref(JSON.parse(JSON.stringify(blankMailchimpAccount)))
    const resetAccountData = () => {
      mailchimpAccount.value = JSON.parse(JSON.stringify(blankMailchimpAccount))
    }

    const onSubmit = () => {
      store
        .dispatch('mailchimp/createAccountMailchimp', mailchimpAccount.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-update-mailchimp-account-sidebar-active', false)
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetAccountData,
    )

    return {
      toast,
      mailchimpAccount,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-update-mailchimp-account-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
