<template>
  <div>
    <mailchimp-template-view
      :is-view-mailchimp-template-sidebar-active.sync="isViewMailchimpTemplateSidebarActive"
      :region-options="regionOptions"
      :mailchimp-template="mailchimpTemplate"
      @refetch-data="refreshTable"
    />
    <preview-template
      :is-preview-template.sync="isPreviewTemplate"
      :html-preview="currentHtml"
    />
    <!-- Filters -->
    <region-filters
      :region-options="regionOptions"
      :region-filter.sync="regionFilter"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refMailchimpTemplates"
          class="position-relative"
          :items="fetchMailChimpTemplates"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(regionId)="data">
            <span>{{ data.item.regionId.toUpperCase() }}</span>
          </template>
          <template #cell(account)="data">
            <span>{{ data.item.account.name }}</span>
          </template>
          <template #cell(mailAction)="data">
            <span>{{ data.item.action.length > 0 ? 'In use' : 'Not use' }}</span>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-link @click="showModal(data.item)">
              Detail
            </b-link>
            <b-link @click="preview(data.item)">
              Preview
            </b-link>
          </template>
          <!-- Column: Actions -->
          <template #cell(created_at)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.created_at) }}
            </span>
          </template>
          <!-- Column: Actions -->
          <template #cell(updated_at)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.update_at) }}
            </span>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTemplates"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BLink,
  BPagination,
} from 'bootstrap-vue'
import store from '@/store'

import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import { mapGetters } from 'vuex'
import mailchimpTemplateView from '@/components/mailchimp/templates/mailchimpTemplateView.vue'
import PreviewTemplate from '@/components/mailchimp/templates/PreviewTemplate.vue'
import { unescape } from 'querystring'
import useMailchimpTemplates from './useMailchimpTemplates'
import { formatDate } from '../../../utils/utils'
import RegionFilters from "@/components/mailchimp/action/RegionFilters.vue";

export default {
  components: {
    BOverlay,
    PreviewTemplate,
    mailchimpTemplateView,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BPagination,
    vSelect,
    RegionFilters
  },
  setup() {
    // Use toast
    const currentHtml = ref('')
    const mailchimpTemplate = ref({})
    const toast = useToast()
    const isPreviewTemplate = ref(false)
    const isViewMailchimpTemplateSidebarActive = ref(false)
    const dataFetchRoles = ref([])
    const userCheckboxOptions = ref([])
    const userCheckbox = []
    const onResetPassConfirm = () => {
      store
        .dispatch('user/resetPass', userCheckboxOptions.value)
        .then(response => {
          if (response.request.status === 201) {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Reset pass successfuly',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error action reset password',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const PushToArray = (value, ...args) => {
      if (value[0] === true) {
        userCheckboxOptions.value.push(args[0])
      } else {
        userCheckboxOptions.value = userCheckboxOptions.value.filter(
          item => item !== args[0],
        )
      }
    }
    const {
      timeout,
      showTable,
      fetchMailChimpTemplates,
      tableColumns,
      perPage,
      currentPage,
      totalTemplates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMailchimpTemplates,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      regionFilter,
    } = useMailchimpTemplates()
    return {
      timeout,
      showTable,
      currentHtml,
      isPreviewTemplate,
      mailchimpTemplate,
      formatDate,
      onResetPassConfirm,
      PushToArray,
      // Sidebar
      dataFetchRoles,
      isViewMailchimpTemplateSidebarActive,
      fetchMailChimpTemplates,
      tableColumns,
      perPage,
      currentPage,
      totalTemplates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMailchimpTemplates,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      planOptions,
      statusOptions,
      userCheckboxOptions,
      userCheckbox,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      regionFilter,
    }
  },
  data() {
    return {
      modalShow: false,
      newRole: null,
      nameState: null,
    }
  },
  computed: {
    ...mapGetters('app', ['regionOptions']),
  },
  methods: {
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 600)
    },
    preview(data) {
      store
        .dispatch('mailchimp/previewTemplate', data.id)
        .then(response => {
          this.currentHtml = response.body.html
          this.isPreviewTemplate = true
        })
        .catch(e => {
        })
    },
    showModal(data) {
      const mailActionArray = data.action.map(x => `(${x.regionId.toUpperCase()})${x.name}`)

      const transferData = {
        name: data.name,
        regionId: data.regionId,
        mailAction: mailActionArray,
        account: data.account.name,
      }
      this.mailchimpTemplate = transferData
      this.isViewMailchimpTemplateSidebarActive = true
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
