import {
  ref,
  watch,
  computed,
} from '@vue/composition-api'
import store from '@/store'
import {
  title,
} from '@core/utils/filter'

// Notification
import {
  useToast,
} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMailchimpTemplates() {
  // Use toast
  const toast = useToast()
  const showTable = ref(false)
  const refMailchimpTemplates = ref(null)

  // Table Handlers
  const tableColumns = [{
    key: 'name',
    sortable: false,
  },
  {
    key: 'regionId',
    sortable: false,
  },
  {
    label: 'Mail action',
    key: 'mailAction',
  },
  {
    label: 'Account',
    key: 'account',
  },
  {
    key: 'created_at',
    formatter: title,
    sortable: false,
  },
  {
    key: 'updated_at',
    sortable: false,
  },
  {
    key: 'actions',
  },
  ]
  const perPage = ref(10)
  const totalTemplates = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const regionFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refMailchimpTemplates.value ? refMailchimpTemplates.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTemplates.value,
    }
  })

  const refetchData = () => {
    refMailchimpTemplates.value.refresh()
  }

  const fetchMailChimpTemplates = (ctx, callback) => {
    store
      .dispatch('mailchimp/fetchMailChimpTemplates', {
        q: searchQuery.value,
        take: perPage.value,
        page: currentPage.value,
        region_id: regionFilter.value,
      })
      .then(response => {
        const {
          data: templates,
          meta,
        } = response.body
        callback(templates)
        totalTemplates.value = meta.itemCount
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching templates list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const timeout = ref(null)
  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter, regionFilter], () => {
    showTable.value = true
         clearTimeout(timeout.value)

    timeout.value = setTimeout(() => {
      refetchData()
      showTable.value = false
    }, 2000)
  })
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status =>
    // TODO change to is Active
    // if (status === 'active') return 'success'
    // if (status === 'inactive') return 'secondary'
    'success'

  return {
    timeout,
    showTable,
    fetchMailChimpTemplates,
    tableColumns,
    perPage,
    currentPage,
    totalTemplates,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMailchimpTemplates,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    regionFilter
  }
}
